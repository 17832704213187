@font-face {
  font-family: "JetBrainsMono";
  src: url("assets/JetBrainsMono-1.0.3/web/eot/JetBrainsMono-Regular.eot");
  src: url("assets/JetBrainsMono-1.0.3/web/eot/JetBrainsMono-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("assets/JetBrainsMono-1.0.3/web/woff2/JetBrainsMono-Regular.woff2")
      format("woff2"),
    url("assets/JetBrainsMono-1.0.3/web/woff/JetBrainsMono-Regular.woff")
      format("woff"),
    url("assets/JetBrainsMono-1.0.3/ttf/JetBrainsMono-Regular.ttf")
      format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SF UI Display Thin";
  font-weight: normal;
  src: local("SF UI Display Thin"),
    url("assets/sf-ui-display-cufonfonts-webfont/sf-ui-display-thin-58646e9b26e8b.woff")
      format("woff");
  font-display: swap;
}

:root {
  --white: #f7f7f7;
  --black: #353535;
  --black-alternate: #252525;
  --grey: #e0dcdc;
  --light-violet: #9c1de7;
  --dark-violet: #581b98;
  --red: #ff073a;
  --yellow: #fff707;
  --green: #07ffa8;
  --lg-one: var(--light-violet), var(--red), var(--yellow);
  --lg-two: var(--dark-violet), var(--light-violet), var(--red);
  --lg-three: var(--red), var(--light-violet), var(--dark-violet);
}

@media (prefers-color-scheme: dark) {
  .load {
    --bg-color: var(--black);
    --bg-color-alternate: var(--black-alternate);
    --color: var(--white);
    --lg-text: var(--lg-one);
    --lg-border: var(--lg-three);
    --highlight-color: var(var(--dark-violet));
    --arrow-color: var(--yellow);
    --dot-color: var(--color)
  }
  nav .toggle-mode span:not(.for-dark),
  img:not(.for-dark),
  svg:not(.for-dark) {
    display: none;
  }
}
@media (prefers-color-scheme: light) {
  .for-dark {
    display: none;
  }
  .load {
    --bg-color: var(--white);
    --bg-color-alternate: var(--grey);
    --color: var(--black);
    --lg-text: var(--lg-two);
    --lg-border: var(--lg-one);
    --highlight-color: var(--white);
    --arrow-color: var(--red);
    --dot-color: var(--bg-color)
  }
}
/* - - - - - - - - - - - - - - - - - - - -  */
.light-mode {
  --bg-color: var(--white);
  --bg-color-alternate: var(--grey);
  --color: var(--black);
  --lg-text: var(--lg-two);
  --lg-border: var(--lg-one);
  --highlight-color: var(--white);
  --arrow-color: var(--red);
}

.dark-mode {
  --bg-color: var(--black);
  --bg-color-alternate: var(--black-alternate);
  --color: var(--white);
  --lg-text: var(--lg-one);
  --lg-border: var(--lg-three);
  --highlight-color: var(--dark-violet);
  --arrow-color: var(--yellow);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-color);
  color: var(--color);
  max-width: 100vw;
  overflow-x: hidden;
}

.transition {
  transition: 0.3s ease all;
}

.menu {
  z-index: 3;
  position: fixed;
  top: 1rem;
  left: 1rem;
  backdrop-filter: blur(10px);
}

.button-menu {
  display: block !important;
}

.centered {
  text-align: center;
  padding: 1rem;
}

.padded-top {
  padding-top: 2rem;
}
.padded-bottom {
  padding-bottom: 2rem;
}

nav {
  display: flex;
  position: fixed;
  font-family: "SF UI Display Thin", sans-serif;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 100vh;
  width: 100vw;
  background-color: var(--bg-color);
  z-index: 2;
}

.line {
  fill: none;
  stroke: var(--color);
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

nav img {
  align-self: flex-start;
  top: 1rem;
  right: 1rem;
  position: fixed;
  z-index: -1;
}

.menu,
nav img,
a {
  cursor: pointer;
}

a {
  align-self: center;
}

a:link,
a:visited {
  text-decoration: none;
  color: var(--color);
}

section {
  font-family: "Rubik", sans-serif;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  height: max(100vh, 1000px);
  width: max(100vw, 300px);
  position: relative;
}

section#think {
  flex-direction: column;
}
#think-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 auto;
  width: 70%;
  gap: 2rem;
}
#think-wrapper > p {
  max-width: 25%;
  ;
}
.man-think {
  z-index: 1;
}

section:not(.hero-wrapper):nth-child(2n+1) {
  background-color: var(--bg-color-alternate);
}

h1 {
  font-size: 2.6rem;
}

h3 {
  font-size: 1.3rem;
}

h3 span {
  font-size: 5.5rem;
  background: linear-gradient(to left, var(--lg-text));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1 span {
  position: relative;
  background-image: linear-gradient(to right, var(--lg-border));
  background-position: 0 85%;
  background-size: 100% 0.22em;
  background-clip: border-box;
  background-attachment: scroll;
  background-origin: padding-box;
  background-repeat: no-repeat;
}

h1 sup {
  font-size: 1.5rem;
  color: var(--grey);
  position: absolute;
}

h2 {
  font-family: "JetBrainsMono", "Courier New", Courier, monospace;
  font-weight: normal;
  font-size: 1.1rem;
}

rect {
  transition: none;
}

.woman-design {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

div img,
div svg {
  width: 100%;
}

p, p + ul {
  font-family: "SF UI Display Thin", sans-serif;
  font-size: 1.1rem;
}

p + ul > li {
  list-style: '__ ';
}

.dark-mode nav .toggle-mode span:not(.for-dark),
.dark-mode img:not(.for-dark),
.dark-mode svg:not(.for-dark) {
  display: none;
}
.light-mode nav .toggle-mode span:not(.for-dark),
.light-mode img:not(.for-dark),
.light-mode svg:not(.for-dark) {
  display: inline;
}

.light-mode .for-dark {
  display: none;
}
.dark-mode .for-dark {
  display: inline;
}

.check,
.error {
  display: inline !important;
}

.do-you {
  font-size: 3.2rem;
}

input {
  font-family: "SF UI Display Thin", sans-serif;
  width: 100%;
  background: transparent;
  color: var(--color);
  justify-content: space-between;
  border: 0;
  border-radius: 0;
  padding: 1rem 0;
  border-bottom: 0.1rem var(--color) solid;
}

input:focus {
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px var(--color) inset;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--color) !important;
}

::placeholder {
  color: var(--color);
}

button, #calendly-link {
  font-family: "SF UI Display Thin", sans-serif;
  font-weight: bolder;
  justify-self: center;
  align-self: center;
  border: 0.1rem var(--color) solid;
  padding: 1rem 2rem;
  background-color: transparent;
  color: var(--color);
  cursor: pointer;
  text-align: center;
}
button:focus {
  outline: none;
}

.send-button {
  width: 100%;
  text-align: center;
}

.check,
.error {
  opacity: 0;
  position: absolute;
}

.hide {
  display: none;
}

.show-hide-symbol {
  animation: HideSymbol 10s;
}

#thanks {
  justify-content: center;
  width: 100%;
  pointer-events: none;
}

#thanks div {
  flex-direction: column;
}

#thanks h4 span {
  background: linear-gradient(to right, var(--lg-text));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#thanks p {
  text-align: left;
}
#thanks a:link {
  text-decoration: underline;
}

.p-thanks-hidden {
  display: none;
}

@keyframes HideSymbol {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.required {
  border-bottom: 0.1rem var(--red) solid;
  background: linear-gradient(
    to left,
    var(--bg-color) 35%,
    var(--red),
    var(--bg-color) 65%
  );
  background-size: 400%;
  animation: RequiredInputWarning 2s;
}

.errored {
  background: linear-gradient(
    to left,
    var(--bg-color) 35%,
    var(--red),
    var(--bg-color) 65%
  );
  background-size: 400%;
  animation: ErroredInputWarning 2s;
}

@keyframes ErroredInputWarning {
  from {
    background-position: 100% 0%;
    color: var(--red);
    border: 0.1rem var(--red) solid;
  }
  to {
    background-position: 0% 100%;
    color: var(--color);
    border: 0.1rem var(--color) solid;
  }
}

.success {
  background: linear-gradient(
    to left,
    var(--bg-color) 35%,
    var(--light-violet),
    var(--bg-color) 65%
  );
  background-size: 400%;
  animation: SuccededInputWarning 2s;
}

@keyframes SuccededInputWarning {
  from {
    background-position: 100% 0%;
    color: var(--light-violet);
    border: 0.1rem var(--light-violet) solid;
  }
  to {
    background-position: 0% 100%;
    color: var(--color);
    border: 0.1rem var(--color) solid;
  }
}

@keyframes RequiredInputWarning {
  from {
    background-position: 100% 0%;
  }
  to {
    background-position: 0% 100%;
  }
}

#gradient-canvas {
  width:100%;
  height: max(100vh, 1000px);
}

.dark-mode #gradient-canvas {
  --gradient-color-1: var(--black);
  --gradient-color-2: var(--red);
  --gradient-color-3: var(--light-violet);
  --gradient-color-4: var(--dark-violet);
}

.light-mode #gradient-canvas {
  --gradient-color-1: var(--white);
  --gradient-color-2: var(--red);
  --gradient-color-3: var(--light-violet);
  --gradient-color-4: var(--grey);
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#projects {
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  overflow: hidden;
}

#projects h1 {
  padding: 2rem 0 2rem 0;
}

.showcase-wrapper {
  width: 70%;
  padding-bottom: 4rem;
}

.showcase-wrapper > * {
  line-height: 1.8rem;
}

.showcase-wrapper ul {
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
}

.showcase-wrapper ul li {
  list-style: none;
  border: 1px solid var(--color);
  padding: 1rem;
}

.showcase-wrapper ul li:hover {
  background-color: var(--highlight-color);
  border: 1px solid var(--bg-color);
}

.showcase-wrapper i {
  transform: rotate(0deg);
  display: inline-block;
}

.showcase-wrapper ul li:hover i {
  color: var(--arrow-color);
  transform: rotate(390deg);
  display: inline-block;
}

details {
  cursor: pointer;
}

@media (max-width: 768px) {
  .hero-contact p,
  input {
    font-size: 0.9rem;
    margin-bottom: 5rem;
  }
  .space input {
    margin: 1rem 0;
  }
  section {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  section div {
    width: 75%;
    margin: 0 auto;
    align-self: flex-start;
  }
  .for-large {
    display: none;
  }

  section#think {
    flex-direction: column;
  }

  h3 span {
    font-size: 3rem;
  }
  #think-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 auto;
    gap: 2rem;
  }
  #think-wrapper > p {
    max-width: 100%;
  }
  .man-think {
    z-index: 1;
  }

  button {
    font-size: 0.9rem;
    margin: 1rem 0;
  }
  .check,
  .error {
    align-self: center;
    width: 1rem;
    margin: 1rem 0.2rem;
  }
  #thanks h4 span {
    font-size: 4rem;
  }
  #thanks h4 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  #thanks p {
    margin-bottom: 1rem;
  }
  #gradient-canvas {
    width:100%;
    height: 100%;
  }

  .showcase-wrapper ul {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
  }
  
}

@media (min-width: 768px) {
  body {
    overflow-x: hidden;
  }
  nav a {
    font-size: 1.2rem;
  }
  .logo {
    min-width: 600px;
  }

  .hero,
  .hero-contact {
    max-width: 600px;
    width: 75%;
    align-self: center;
  }

  section {
    flex-direction: row;
    overflow-x: auto;
    align-items: center;
  }
  .hero-wrapper {
    flex-direction: column;
    text-align: center;
  }

  section>div:not(.hero):not(.hero-contact):not(.showcase-wrapper) {
    flex: 0 0 30%;
  }

  div h2,
  .hero-contact {
    text-align: left;
    width: 50%;
    min-width: 600px;
  }
  div h1 {
    font-size: 3.7rem;
  }
  .hero-contact p,
  input {
    font-size: 1.5rem;
  }
  .space input {
    margin: 2rem 0;
  }
  button {
    font-size: 1.1rem;
    margin: 2rem 0;
  }
  .for-small {
    display: none;
  }
  h3 {
    font-size: 2.5rem;
    height: 12rem;
  }
  h3 span {
    font-size: 11rem;
  }
  .check,
  .error {
    width: 1.2rem;
    margin: 2rem 0.3rem;
  }

  .rustic {
    width: 12%;
    z-index: -100;
    position: absolute;
    top: 5%;
    left: 8%;
  }
  #thanks div {
    width: 60%;
  }
  #thanks img {
    width: 32%;
    position: absolute;
    right: 20%;
    top: 50%;
  }
  #thanks h4 span {
    font-size: 12rem;
  }
  #thanks h4 {
    font-size: 2.5rem;
  }
  #thanks p {
    width: 35%;
  }
  .showcase-wrapper ul {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
}

@media (min-width: 1024px){
  #thanks img {
    width: 38%;
    position: absolute;
    right: 14%;
    top: 45%;
  }
  #thanks h4 span {
    font-size: 14rem;
  }
  #thanks h4 {
    font-size: 2.9rem;
  }
  #thanks p {
    font-size: 1.9rem;
  }
  .showcase-wrapper ul {
    grid-template-columns: repeat(3, minmax(150px, 1fr));
  }
}

@media (min-width: 1800px) {
  #thanks img {
    width: 34%;
    position: absolute;
    right: 19%;
    top: 45%;
  }
  #thanks h4 span {
    font-size: 17rem;
  }
  #thanks h4 {
    font-size: 2.9rem;
  }
  #thanks p {
    font-size: 1.5rem;
  }

  .showcase-wrapper ul {
    grid-template-columns: repeat(5, minmax(150px, 1fr));
  }
}

.extra-margin-top {
  margin-top: 2rem;
}
.extra-margin-left {
  margin-left: 1rem;
}

#calendly-link {
  display: inline-block;
}

code {
  background-color: var(--color);
  border: 1px solid var(--color);
  border-radius: 3px;
  margin-inline-start: 0.5rem;
  margin-left: 0.5rem;
  color: var(--bg-color);
}

#dot {
  width: 500px;
  height: 500px;
  border-radius: 100%;
  position: absolute;
  left: -9999px;
  top: -9999px;
  background-color: var(--dot-color);
  opacity: .7;
  filter: invert(80%) blur(5rem);
  z-index: 100000;
  mix-blend-mode: difference;
  pointer-events: none;
}

.light-mode #dot {
  filter: invert(20%) blur(1rem);
  mix-blend-mode: lighten;
}